import { JsonObj } from "types";

const getDisplayData = (data: JsonObj | null): JsonObj => {
  let returnData: JsonObj = {
    companyId: "",
    language: { name: "", id: 0 },
    languageDesc: "",
    product: {
      internalCode: "",
      gtinNumber: "",
      image: "",
      imageShadow: true,
      name: "",
      appellationControlStatement: "",
      traditionalTerms: "",
      pdoPgiSymbols: [""],
      producerCompanyOrCellar: "",
      countryIconCode: "",
      country: "",
      category: "",
      color: "",
      biologic: false,
      showDetailsSection: false,
      hiddenIngredientsSections: false,
      hiddenNutritionalSection: false,
      hiddenRespConsumpSection: false,
      hiddenWasteDisposalSection: false,
      templateId: "default",
    },
    netQuantityData: [
      {
        netQuantity: 0,
        netQuantityFormat: "",
      },
    ],
    foodBusinessOperator: {
      sectionLabel: "",
      type: "",
      name: "",
      address: "",
      city: "",
      country: "",
      province: "",
      uiLabel: "",
    },
    foodBusinessOperatorImporter: {
      sectionLabel: "",
      type: "",
      name: "",
      address: "",
      city: "",
      country: "",
      uiLabel: "",
    },
    responsibleConsumption: {
      sectionLabel: "",
      messageonrisk: "",
      messageonresponsibleconsumption: "",
      pictograms: [],
    },
    ingredientData: [
      {
        groupName: "",
        separator: "",
        pattern: "",
        order: 0,
        ingredients: [
          {
            order: 0,
            name: "",
            isBold: false,
            isOrganic: false,
          },
        ],
      },
    ],
    packagingGassesMessage: { message: "" },
    allergenPictograms: [],
    characteristic: {
      sectionLabel: "",
      harvestYearLabel: "",
      harvestyear: "",
      alcoholicStrengthPercVolumeLabel: "",
      alcoholicstrengthpercvolume: "",
      productionMethodsTermsLabel: "",
      productionmethodsterms: "",
      servingTemperatureLabel: "",
      servingtemperature: "",
      varietiesLabel: "",
      varieties: "",
      holdingTermsLabel: "",
      holdingterms: "",
      growingAreaLabel: "",
      growingarea: "",
      descriptionLabel: "",
      description: "",
      variablesCharacteristic: [
        { label: "", description: "" },
        { label: "", description: "" },
      ],
      custimCharacteristic: [{ description: "", label: "", imageUrl: "" }],
    },
    tasting: {
      sectionLabel: "",
      colourLabel: "",
      colour: "",
      aromasLabel: "",
      aromas: "",
      tasteLabel: "",
      taste: "",
      winePairingLabel: "",
      winePairing: "",
      customTasting: { result: [{ description: "", title: "" }] },
    },
    nutritionalDeclaration: {
      fatg: 0,
      fiberg: 0,
      proteing: 0,
      proteingPortion: 0,
      sectionLabel: "",
      gridLeftColumnLabel: "",
      gridRightColumnLabel: "",
      energyKj: 0,
      carbohydrateSugarg: 0,
      carbohydrateSugargPortion: null,
      carbohydrateg: 0,
      carbohydrategPortion: null,
      energyKcal: 0,
      energyKcalPortion: null,
      energyKjPortion: null,
      fibergPortion: null,
      fatgPortion: null,
      fatSaturatesg: null,
      fatSaturatesgPortion: null,
      portionml: null,
      saltg: 0,
      saltgPortion: null,
      showSaltTolerance: false,
      showProteinTolerance: false,
      showFiber: false,
      monoUnsatFattyAcidsg: 0,
      monoUnsatFattyAcidsgPortion: null,
      polyolsg: 0,
      polyolsgPortion: null,
      polyunsatFattyAcidsg: 0,
      polyunsatFattyAcidsgPortion: null,
      starchg: 0,
      starchgPortion: null,
      showMonoUnsatFattyAcidsg: false,
      showPolyolsg: false,
      showPolyunsatFattyAcidsg: false,
      showStarchg: false,
      showSaltTolerancePerPortion: false,
      showProteinTolerancePerPortion: false,

    },
    prizesAndAwards: {
      sectionLabel: "",
      awardsList: [],
    },
    attachments: {
      sectionLabel: "",
      attachmentsList: [],
    },
    impressums: {
      impressums: [],
    },
    company: {
      sectionLabel: "",
      name: "",
      address: "",
      city: "",
      contry: "",
      logo: "",
      description: "",
      websiteLink: "",
      facebookLink: "",
      linkedinLink: "",
      twitterLink: "",
      instagramLink: "",
    },
    sustainability: {
      sectionLabel: "",
      message: "",
      wasteDisposalComponents: [],
    },
  };

  if (data) {
    returnData = data;
  }

  return returnData;
};

export default getDisplayData;
